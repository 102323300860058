@import url("https://v1.fontapi.ir/css/SFMono:400");
@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono&display=swap");
@font-face {
  font-family: "Projection";
  src: url("https://okpc.app/fonts/projection.woff") format("woff");
}

:root {
  /* Main Colors */
  --chakra-colors-gray-500: hsl(0, 0%, 50%);
  --chakra-colors-green-500: hsl(155, 100%, 43%);
  --chakra-colors-blue-500: hsl(216, 100%, 50%);
  --chakra-colors-purple-500: hsl(281, 100%, 50%);
  --chakra-colors-yellow-500: hsl(39, 100%, 50%);
  --chakra-colors-pink-500: hsl(323, 100%, 50%);

  /* Links */
  --chakra-colors-whiteAlpha-300: rgba(255, 255, 255, 0.16);

  /* Borders */
  --chakra-colors-gray-800: hsl(0, 0%, 14%);

  /* Background */
  --chakra-colors-gray-900: hsl(0, 0%, 2%);

  --chakra-fonts-heading: "Projection", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol" !important;
  --chakra-fonts-body: "JetBrains Mono", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol" !important;
  --chakra-fonts-mono: "JetBrains Mono", "SF Mono", "SFMono-Regular", Menlo,
    Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --chakra-fonts-copy: "JetBrains Mono", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

main {
  height: 100%;
  flex: 1 1 auto;
}
